import React from "react";
import * as d3 from "d3";
import BarChartModel from "../BarChartModel";

interface Props {
  id: string;
  data: BarChartModel[];
}

interface State {}

class OtherBarChart extends React.Component<Props, State> {
  chRef: any;

  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.chRef = React.createRef();
  }

  componentDidMount() {
    this.initOne();
  }
  // componentDidUpdate() {
  //   this.initOne();
  // }

  initOne() {
    const { data: propsData } = this.props;
    const data: any = [];
    propsData.forEach((q) => {
      const obj: any = {};
      obj.group = q.name;
      q.barChart.forEach((p) => {
        obj[p.name] = p.value;
      });
      data.push(obj);
    });
    //console.log("data", propsData);
    // set the dimensions and margins of the graph
    var margin = { top: 10, right: 30, bottom: 60, left: 50 },
      width = 600 - margin.left - margin.right,
      height = 400 - margin.top - margin.bottom;

    // append the svg object to the body of the page
    var svg = d3
      .select(`#${this.props.id}`)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // Parse the Data

    // console.log("data", data);
    // List of subgroups = header of the csv files = soil condition here
    // var subgroups = data.columns.slice(1);
    var subgroups: string[] = [];
    propsData.forEach((q) => {
      q.barChart.forEach((r) => {
        const ExistSubGroup = subgroups.find((q) => q === r.name);
        if (!ExistSubGroup) {
          subgroups.push(r.name);
        }
      });
    });
    // console.log("subgroups", subgroups);
    // List of groups = species here = value of the first column called group -> I show them on the X axis
    var groups = d3.map(propsData, function (d: any) {
      return d.name;
    });

    // console.log("groups", groups);

    // Add X axis
    var x = d3.scaleBand().domain(groups).range([0, 500]).padding([0.2]);
    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x).tickSize(0))
      .attr("font-size", "12px");

    // Add Y axis

    var y = d3.scaleLinear().domain([0, 80]).range([height, 0]);
    svg
      .append("g")
      // .tickFormat(formatPercent)
      // .format("%")
      .call(
        d3.axisLeft(y).tickFormat(function (d: any) {
          return d + "%";
        })
      );
    // Another scale for subgroup position?
    var xSubgroup = d3
      .scaleBand()
      .domain(subgroups)
      .range([0, x.bandwidth()])
      .padding([0.2]);

    // color palette = one color per subgroup
    var color = d3
      .scaleOrdinal()
      .domain(subgroups)
      .range([
        "#3C1414",
        "#918151",
        "#C23B22",
        "#FF8C00",
        "#002E63",
        "#318CE7",
      ]);

    // Show the bars
    svg
      .append("g")
      .selectAll("g")
      // Enter in data = loop group per group
      .data(data)
      .enter()
      .append("g")
      .attr("transform", function (d: any) {
        return "translate(" + x(d.group) + ",0)";
      })
      .selectAll("rect")
      .data(function (d: any) {
        return subgroups.map(function (key: any) {
          // console.log("key", d, key);
          return { key: key, value: d[key] };
        });
      })
      .enter()
      .append("rect")
      .attr("x", function (d: any) {
        // console.log('xsub', d.key);
        return xSubgroup(d.key);
      })
      .attr("y", function (d: any) {
        // console.log("dDotValue", y(d.value));
        return y(d.value);
      })
      .attr("width", xSubgroup.bandwidth())
      .attr("height", function (d: any) {
        return height - y(d.value);
      })
      .attr("fill", function (d: any) {
        return color(d.key);
      });

    var legendElement = svg
      .append("g")
      .attr("class", "legend__container")
      .attr("transform", `translate(${5}, ${5})`) // set our group position to the end of the chart
      .attr("transform", "rotate(-90)")
      .selectAll("g.legend__element")
      .data(subgroups) // use the scale domain as data
      .enter()
      .append("g")
      .attr("transform", function (d: any, i: any) {
        return `translate(${0}, ${i * 45})`; // provide an offset for each element found in the domain
      })
      .attr("transform", function (d: any, i: any) {
        
        var c = 1;   // number of columns
        var h = 60;  // legend entry height
        var w = -20; // legend entry width (so we can position the next column) 
        var tx = -80; // tx/ty are essentially margin values
        var ty = -20;
        var x = i % c * w + tx;
        var y = Math.floor(i / c) * h + ty;
        return "translate(" + x + "," + y + ")";
    });

    legendElement
      .append("text")
      .attr("x", -80)
      .attr("y", 380)
      .attr("font-size", "11px")
      .attr("transform", "rotate(90 -60 150)")
      .text((d: any) => d);

    legendElement
      .append("rect")
      .attr("x", -292)
      .attr("y", 117)
      .attr("width", 10)
      .attr("height", 10)
      .attr("fill", function (d: any) {
        // console.log('color', d);
        return color(d);
      });
  }

  render() {
    const {id} = this.props;
    return <div id={id} ></div>;
  }
}

export default OtherBarChart;
