import React from "react";
import * as d3 from "d3";
import ChartModel from "../ChartModel";

interface Props {
  id: string;
  data: ChartModel[];
}

interface State {}

class SimpleBarChart extends React.Component<Props, State> {
  componentDidMount() {
    this.init();
  }

  init() {
    const { data: propsData } = this.props;
    const data: any = [];
    propsData.forEach((q) => {
      data.push(q);
    });

    // console.log('mainData', data);

    // set the dimensions and margins of the graph
    var margin = { top: 30, right: 30, bottom: 100, left: 50 },
      width = 600 - margin.left - margin.right,
      height = 400 - margin.top - margin.bottom;

    // append the svg object to the body of the page
    var svg = d3
      .select(`#${this.props.id}`)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // X axis
    var x = d3
      .scaleBand()
      .range([0, width])
      .domain(
        data.map(function (d: any) {
          return d.name;
        })
      )
      .padding(0.2);
    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x))
      .selectAll("text")
      .attr("font-size", "12px")
      .attr("transform", "translate(-10,0)rotate(-20)");
    // .style("text-anchor", "end");

    // Add Y axis
    var y = d3.scaleLinear().domain([0, 50]).range([height, 0]);
    svg.append("g").call(d3.axisLeft(y));

    // color palette = one color per subgroup
    var color = d3
      .scaleOrdinal()
      .domain(
        data.map(function (d: any) {
          return d.name;
        })
      )
      .range(
        data.map(function (d: any) {
          return d.color;
        })
      );

    // Bars
    svg
      .selectAll("mybar")
      .data(data)
      .enter()
      .append("rect")
      .attr("x", function (d: any) {
        return x(d.name);
      })
      .attr("y", function (d: any) {
        return y(d.value);
      })
      .attr("width", x.bandwidth())
      .attr("height", function (d: any) {
        return height - y(d.value);
      })
      // .attr("fill", "#69b3a2")
      .attr("fill", function (d: any) {
        return color(d);
      });

    var legendElement = svg
      .append("g")
      .attr("class", "legend__container")
      .attr("transform", `translate(${5}, ${5})`) // set our group position to the end of the chart
      .attr("transform", "rotate(-90)")
      .selectAll("g.legend__element")
      .data(
        data.map(function (d: any) {
          return d.name;
        })
      ) // use the scale domain as data
      .enter()
      .append("g")
      .attr("transform", function (d: any, i: any) {
        return `translate(${0}, ${i * 45})`; // provide an offset for each element found in the domain
      })
      .attr("transform", function (d: any, i: any) {
        var c = 4; // number of columns
        var h = 95; // legend entry height
        var w = -20; // legend entry width (so we can position the next column)
        var tx = -20; // tx/ty are essentially margin values
        var ty = 40;
        var x = (i % c) * w + tx;
        var y = Math.floor(i / c) * h + ty;
        return "translate(" + x + "," + y + ")";
      });

    legendElement
      .append("text")
      .attr("x", -190)
      .attr("y", 380)
      .attr("font-size", "12px")
      .attr("transform", "rotate(90 -60 150)")
      .text((d: any) => d);

    legendElement
      .append("rect")
      .attr("x", -290)
      .attr("y", 8)
      .attr("width", 10)
      .attr("height", 10)
      .attr("fill", function (d: any) {
        return color(d);
      });
  }

  render() {
    const { id } = this.props;
    return <div id={id}></div>;
  }
}

export default SimpleBarChart;
